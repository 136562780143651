import React, { ComponentType, ReactNode, useEffect, useState } from 'react';
import StepTracker from '../../components/StepTracker';

const onboardingPathsShowSteps: { [key: string]: boolean } = {
    '/signup': false,
    '/planselect': false,
    '/electric': true,
    '/gas': true,
    '/confirm': true,
    '/calculating': true,
};

const OnboardingContentWrapper = ({
    ComponentPage,
    usingMobile,
}: {
    ComponentPage: ComponentType<any>;
    usingMobile: boolean;
}) => {
    const [showSteps, setShowSteps] = useState<boolean>(false);

    useEffect(() => {
        if (onboardingPathsShowSteps[window.location.pathname]) {
            setShowSteps(true);
        } else {
            setShowSteps(false);
        }
    }, [window.location.pathname]);

    return (
        <>
            <div
                id="ContentWrapper"
                style={{
                    height: usingMobile ? '100vh' : '100%',
                    width: usingMobile ? '90%' : '50%',
                    display: 'flex',
                    paddingLeft: usingMobile
                        ? '0%'
                        : window.innerWidth >= 1920
                        ? window.innerWidth >= 2100
                            ? window.innerWidth >= 2460
                                ? '15%'
                                : '13%'
                            : '11.5%'
                        : '7.25%',
                    paddingRight: usingMobile
                        ? '0%'
                        : window.innerWidth >= 1920
                        ? window.innerWidth >= 2100
                            ? window.innerWidth >= 2460
                                ? '15%'
                                : '13%'
                            : '11.5%'
                        : '7.25%',
                    flexDirection: 'column',
                    marginLeft: usingMobile ? 'unset' : '50%',
                    overflowX: usingMobile ? 'unset' : 'hidden',
                }}
            >
                <div style={{ height: showSteps ? '11vh' : usingMobile ? '5vh' : '7vh' }}>
                    {showSteps && <StepTracker onboardingPathsShowSteps={onboardingPathsShowSteps}></StepTracker>}
                </div>
                <div
                    style={{
                        height: usingMobile ? (showSteps ? '87vh' : '95vh') : '87.5vh',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: usingMobile ? (showSteps ? '2vh' : '') : '1.5vh',
                    }}
                >
                    <ComponentPage />
                </div>
            </div>
        </>
    );
};

export default OnboardingContentWrapper;
