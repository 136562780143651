import { combineReducers } from 'redux';
import sessionReducer from './session';
import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import regionReducer from './region';
import titleDataReducer from './titleData';
import stepReducer from './step';
import userFormDataReducer from './userFormData';
import mobileReducer from './mobile';
import utilityReducer from './utilities';
import stripeReducer from './stripe';
import socketReducer from './socket';

const rootReducer = combineReducers({
    session: sessionReducer,
    region: regionReducer,
    titleData: titleDataReducer,
    step: stepReducer,
    userFormData: userFormDataReducer,
    mobile: mobileReducer,
    utilities: utilityReducer,
    stripe: stripeReducer,
    socket: socketReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default configureStore;
