import { io, Socket } from 'socket.io-client';
const environment = process.env.REACT_APP_ENVIRONMENT;
const io_backend_url = process.env.REACT_APP_IO_BACKEND_URL;
const ca_backend_url = process.env.REACT_APP_CA_BACKEND_URL;
const com_backend_url = process.env.REACT_APP_COM_BACKEND_URL;

class SocketService {
    private static instance: SocketService;
    private socket: Socket | null = null;

    private constructor() {}

    public static getInstance(): SocketService {
        if (!SocketService.instance) {
            SocketService.instance = new SocketService();
        }
        return SocketService.instance;
    }

    connect(roomIdentifier: string, hostname: string): void {
        let backend_url = '';

        if (hostname.endsWith('secure.wattsons.io')) {
            backend_url = io_backend_url!;
        } else if (hostname.endsWith('secure.wattsons.ca')) {
            backend_url = ca_backend_url!;
        } else if (hostname.endsWith('secure.kergons.com')) {
            backend_url = com_backend_url!;
        } else if (environment === 'dev') {
            backend_url = ca_backend_url!;
        }
        this.socket = io(backend_url);
        this.socket.emit('join', { room: roomIdentifier });
        this.socket.on('connect', () => {
            console.log('Connected to WebSocket server');
        });
    }

    on(event: string, func: (data: any) => void): void {
        if (!this.socket) {
            console.error('Socket connection not established');
            return;
        }
        this.socket.on(event, func);
    }

    emit(event: string, data?: any): void {
        if (!this.socket) {
            console.error('Socket connection not established');
            return;
        }
        this.socket.emit(event, data);
    }

    disconnect(): void {
        console.log('CLOSING SOCKET')
        if (this.socket) {
            this.socket.close();
        }
    }
}

export default SocketService;
