import { v4 as uuidv4 } from 'uuid';

const SET_SCRAPING_SOCKET_CONNECTED = 'agent/setScrapingSocketConnected';
const SET_SCRAPING_SOCKET_ROOM_ID = 'agent/setScrapingSocketRoomId';

const setScrapingSocketConnectedAction = (scrapingSocketConnected: boolean) => {
    return {
        type: SET_SCRAPING_SOCKET_CONNECTED,
        payload: scrapingSocketConnected,
    };
};

const setScrapingSocketRoomIdAction = (scrapingProgressRoomId: string) => {
    return {
        type: SET_SCRAPING_SOCKET_ROOM_ID,
        payload: scrapingProgressRoomId,
    };
};

export const setScrapingSocketConnected = (scrapingSocketConnected: boolean) => (dispatch: any) => {
    dispatch(setScrapingSocketConnectedAction(scrapingSocketConnected));
    return;
};

export const createAndSetScrapingProgressRoomId = () => (dispatch: any) => {
    const scrapingProgressRoomId = uuidv4();
    localStorage.setItem(
        'kergons-scraping-progress-room-id',
        JSON.stringify({ scraping_progress_room_id: scrapingProgressRoomId }),
    );
    dispatch(setScrapingSocketRoomIdAction(scrapingProgressRoomId));
    return scrapingProgressRoomId;
};

const initialState = { scrapingSocketConnected: false, scrapingProgressRoomId: "" };

const socketReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_SCRAPING_SOCKET_CONNECTED:
            newState = { ...state };
            newState.scrapingSocketConnected = action.payload;
            return newState;
        case SET_SCRAPING_SOCKET_ROOM_ID:
            newState = { ...state };
            newState.scrapingProgressRoomId = action.payload;
            return newState;
        default:
            return state;
    }
};

export default socketReducer;
