import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import * as userFormDataActions from '../../store/userFormData';
import LoadingSpinnerContainer from '../../components/LoadingSpinnerContainer';
import { Utility } from '../../CustomTypings';
import LoadingSpinner from '../../components/LoadingSpinnerContainer/loadingSpinner';
import { force_minimum_load } from '../../utility_logic/minimum_loading';
import LoadingText from '../../components/LoadingSpinnerContainer/loadingText';
import { navigate_wrapper } from '../../utility_logic/navigate_wrapper';
import { setCurrentStep, setMaxStep } from '../../store/step';
import clsx from 'clsx';
import { createAndSetScrapingProgressRoomId } from '../../store/socket';

const createUpdateUtilCreds = async (userData: { username: string; password: string; utility_id: string }) => {
    const response = await fetch_wrapper(`/create-update-util-creds`, {
        method: 'POST',
        body: JSON.stringify(userData),
        credentials: 'include',
    });
    return response.status;
};

const scrapeHistoricalData = (utilityId: string, scrapingProgressRoomId: string) => {
    fetch_wrapper(`/scrape-historical-data`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ utility_id: utilityId, room_id: scrapingProgressRoomId }),
    });
};

const ElectricCredsPage = ({
    validSession,
    usingMobile,
    eleUtilities,
}: {
    validSession: boolean;
    usingMobile: boolean;
    eleUtilities: Utility[];
}) => {
    const [eleUsername, setEleUsername] = useState('');
    const [elePassword, setElePassword] = useState('');
    const [eleUtil, setEleUtil] = useState<Utility | null>(
        JSON.parse(sessionStorage.getItem('kergons-onboarding-form-information')!)?.electric_provider || null,
    );
    const [eleFetching, setEleFetching] = useState(false);
    const [validEleCreds, setEleValidCreds] = useState<boolean | null>(null);
    const [usernameFocused, setUsernameFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [credentialsAlreadyInUse, setCredentialsAlreadyInUse] = useState(false);
    const [unavailableLoading, setUnavailableLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const handleEleSubmit = () => {
        setSubmitted(true);
        setCredentialsAlreadyInUse(false);
        setEleValidCreds(null);
        if (eleUtil && eleUsername && elePassword) {
            if (eleUtil.is_available) {
                setEleFetching(true);
            } else {
                setUnavailableLoading(true);
            }
            const waitPromise = force_minimum_load();
            createUpdateUtilCreds({
                username: eleUsername.trim(),
                password: elePassword,
                utility_id: eleUtil.id,
            }).then(async (responseCode) => {
                if (responseCode === 200) {
                    if (!eleUtil.is_available) {
                        fetch_wrapper(`/verify-utility-credentials`, {
                            method: 'POST',
                            body: JSON.stringify({
                                utility_id: eleUtil.id,
                            }),
                            credentials: 'include',
                        })
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (!data.failed_verification) {
                                    setEleValidCreds(true);
                                    
                                    const scrapingProgressRoomId = dispatch(createAndSetScrapingProgressRoomId())
                                    scrapeHistoricalData(eleUtil.id, scrapingProgressRoomId);

                                    await waitPromise;
                                    dispatch(setMaxStep(4));
                                    dispatch(setCurrentStep(4));
                                    dispatch(userFormDataActions.setEleUtilId(eleUtil.id));
                                    navigate_wrapper(navigate, location, '/gas');
                                } else {
                                    await waitPromise;
                                    setSubmitted(false);
                                    setEleFetching(false);
                                    setEleValidCreds(false);
                                }
                            });
                    } else {
                        await waitPromise;
                        dispatch(setMaxStep(4));
                        dispatch(setCurrentStep(4));
                        dispatch(userFormDataActions.setEleUtilId(eleUtil.id));
                        navigate_wrapper(navigate, location, '/gas');
                    }
                } else if (responseCode === 403) {
                    await waitPromise;
                    setSubmitted(false);
                    setEleFetching(false);
                    setUnavailableLoading(false);
                    setCredentialsAlreadyInUse(true);
                    setEleUsername('');
                    setElePassword('');
                }
            });
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && eleUtil && eleUsername.trim() && elePassword && !eleFetching) {
                handleEleSubmit();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [eleUtil, eleUsername, elePassword, eleFetching]);

    // useEffect(() => {
    //     fetch_wrapper(`/get-user-util-status`, {
    //         method: 'GET',
    //         credentials: 'include',
    //     })
    //         .then((response) => response.json())
    //         .then((json) => JSON.parse(json))
    //         .then((data) => {
    //             setEleValidCreds(true);
    //         })
    //         .catch((error) => console.log('error', error));
    // }, []);

    return (
        <>
            {validSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: usingMobile ? '15vh' : '',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: usingMobile ? '1.75rem' : '1.875rem',
                                lineHeight: '36px',
                                height: usingMobile ? '3.5vh' : '',
                            }}
                        >
                            ELECTRICITY UTILITY
                        </div>

                        <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>

                        <div
                            style={{
                                fontFamily: 'AmericanTypewriter',
                                fontWeight: '400',
                                fontSize: '1.125rem',
                                lineHeight: '26px',
                                height: usingMobile ? '8.5vh' : '',
                            }}
                        >
                            Connect your Electricity utility so that we can identify your usage pattern and help you
                            optimize your savings.
                        </div>
                    </div>

                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: usingMobile ? 'space-between' : 'flex-start',
                            alignItems: usingMobile ? 'center' : 'flex-start',
                            width: '100%',
                            height: usingMobile ? '66vh' : '',
                            marginTop: usingMobile ? '6vh' : '5vh',
                            marginBottom: '0px',
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleEleSubmit();
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                height: usingMobile ? '42vh' : '',
                                justifyContent: 'space-between',
                                opacity: submitted ? '0.7' : '1',
                                pointerEvents: submitted ? 'none' : 'auto',
                            }}
                            onClick={(e) => {
                                if (submitted) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: usingMobile ? '6vh' : '',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '0.875rem',
                                        fontWeight: '500',
                                        color: '#495057',
                                    }}
                                >
                                    Electricity Utility
                                </div>
                                <select
                                    style={{
                                        fontFamily: 'Roboto',
                                        fontWeight: '400',
                                        fontSize: usingMobile ? '0.875rem' : '1rem',
                                        color: '#212529',
                                        borderRadius: '5px',
                                        borderColor: '#6C757D',
                                        height: '48px',
                                        padding: '12px',
                                        appearance: 'none',
                                        backgroundImage: `url(/caret-down.svg)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'calc(100% - 10px) center', // Adjust left positioning
                                        backgroundSize: '24px', // Size of the caret
                                        paddingRight: '40px',
                                        paddingLeft: '12px',
                                    }}
                                    value={
                                        eleUtil
                                            ? JSON.stringify(eleUtil)
                                            : JSON.stringify(
                                                  JSON.parse(
                                                      sessionStorage.getItem('kergons-onboarding-form-information')!,
                                                  )?.electric_provider,
                                              ) || undefined
                                    }
                                    onChange={(e) => setEleUtil(JSON.parse(e.target.value))}
                                >
                                    <option value={''} hidden>
                                        Select an option
                                    </option>
                                    {eleUtilities &&
                                        eleUtilities.map((value: Utility) => {
                                            return (
                                                <option
                                                    style={{
                                                        fontFamily: 'Roboto',
                                                        fontWeight: '400',
                                                        fontSize: '1rem',
                                                    }}
                                                    value={JSON.stringify(value)}
                                                    key={value.full_name}
                                                >
                                                    {value.full_name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: usingMobile ? '27vh' : '',
                                    justifyContent: 'space-between',
                                    opacity: eleUtil ? '1' : '0.5',
                                    pointerEvents: eleUtil ? 'unset' : 'none',
                                }}
                                onClick={(e) => {
                                    if (!eleUtil) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        height: usingMobile ? '20vh' : '',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontSize: '0.875rem',
                                                fontWeight: '500',
                                                marginBottom: '1vh',
                                                color: '#495057',
                                            }}
                                        >
                                            Username
                                        </div>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Utility Username"
                                            className="basic_input"
                                            value={eleUsername}
                                            onChange={(e) => setEleUsername(e.target.value)}
                                            onFocus={() => setUsernameFocused(true)}
                                            onBlur={() => setUsernameFocused(false)}
                                            style={{
                                                height: '6vh',
                                                maxHeight: '46px',
                                                width: '100%',
                                                border: '1px solid',
                                                borderRadius: '4px',
                                                padding: '13px',
                                                fontSize: '1rem',
                                                lineHeight: '22px',
                                                fontFamily: 'Roboto',
                                                fontWeight: '400',
                                                borderColor:
                                                    usernameFocused || eleUsername
                                                        ? eleFetching
                                                            ? '#CED4DA'
                                                            : '#6C757D'
                                                        : '#CED4DA',
                                                color: '#212529',
                                                transition: 'border-color 0.3s',
                                            }}
                                        ></input>
                                    </div>

                                    <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontSize: '0.875rem',
                                                fontWeight: '500',
                                                marginBottom: '1vh',
                                                color: '#495057',
                                            }}
                                        >
                                            Password
                                        </div>

                                        <input
                                            autoComplete="off"
                                            type="password"
                                            placeholder="Utility Password"
                                            className="basic_input"
                                            value={elePassword}
                                            onChange={(e) => setElePassword(e.target.value)}
                                            onFocus={() => setPasswordFocused(true)}
                                            onBlur={() => setPasswordFocused(false)}
                                            style={{
                                                height: '6vh',
                                                maxHeight: '46px',
                                                width: '100%',
                                                border: '1px solid',
                                                borderRadius: '4px',
                                                padding: '13px',
                                                fontSize: '1rem',
                                                lineHeight: '22px',
                                                fontFamily: 'Roboto',
                                                fontWeight: '400',
                                                borderColor:
                                                    passwordFocused || elePassword
                                                        ? eleFetching
                                                            ? '#CED4DA'
                                                            : '#6C757D'
                                                        : '#CED4DA',
                                                color: '#212529',
                                                transition: 'border-color 0.3s',
                                            }}
                                        ></input>
                                    </div>
                                </div>
                            </div>

                            {usingMobile && (
                                <div
                                    style={{
                                        height: '2vh',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        marginTop: '3vh',
                                    }}
                                >
                                    {!eleFetching && (
                                        <>
                                            {!validEleCreds && validEleCreds !== null && !credentialsAlreadyInUse && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontFamily: 'Raleway',
                                                        fontSize: '0.875rem',
                                                        width: '100%',
                                                        height: '2vh',
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    Credentials Invalid
                                                </div>
                                            )}
                                            {credentialsAlreadyInUse && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontFamily: 'Raleway',
                                                        fontSize: '0.875rem',
                                                        width: '100%',
                                                        height: '2vh',
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    Credentials already registered
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        {!usingMobile && <div style={{ height: '4vh' }}></div>}

                        {/* {usingMobile && (
                            <div style={{ height: '', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <LoadingSpinnerContainer
                                    loadingText="We received your utility credentials and are processing your data"
                                    active={eleFetching}
                                    usingMobile={usingMobile}
                                ></LoadingSpinnerContainer>
                            </div>
                        )} */}

                        {usingMobile && (
                            <div style={{ width: '80%' }}>
                                <LoadingText
                                    active={eleFetching}
                                    loadingText={'We received your utility credentials and are processing your data'}
                                    usingMobile={usingMobile}
                                ></LoadingText>
                            </div>
                        )}

                        <button
                            type="submit"
                            className={clsx('button submit', {
                                active: eleUtil && eleUsername.trim() && elePassword,
                                loading: eleFetching || unavailableLoading,
                                'mobile mobile-full': usingMobile,
                                'opaque-5': !eleUtil,
                            })}
                            disabled={
                                !(eleUtil && eleUsername.trim() && elePassword) || eleFetching || unavailableLoading
                            }
                        >
                            {unavailableLoading || (usingMobile && eleFetching) ? '' : 'SUBMIT'}

                            <LoadingSpinner
                                active={unavailableLoading || (usingMobile && eleFetching)}
                                usingMobile={usingMobile}
                                large={false}
                            ></LoadingSpinner>
                        </button>
                    </form>

                    {!usingMobile && eleFetching && (
                        <>
                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <LoadingSpinnerContainer
                                    loadingText="We received your utility credentials and are processing your data"
                                    active={eleFetching}
                                    usingMobile={usingMobile}
                                ></LoadingSpinnerContainer>
                            </div>
                        </>
                    )}

                    {!usingMobile && !unavailableLoading && !eleFetching && (
                        <div
                            style={{
                                height: '1.5vh',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                marginTop: '2vh',
                            }}
                        >
                            {!validEleCreds && validEleCreds !== null && !credentialsAlreadyInUse && (
                                <div
                                    style={{
                                        color: 'red',
                                        fontFamily: 'Raleway',
                                        fontSize: '1rem',
                                        width: '100%',
                                        height: '1.5vh',
                                        fontWeight: '400',
                                    }}
                                >
                                    Credentials Invalid
                                </div>
                            )}
                            {credentialsAlreadyInUse && (
                                <div
                                    style={{
                                        color: 'red',
                                        fontFamily: 'Raleway',
                                        fontSize: '1rem',
                                        width: '100%',
                                        height: '1.5vh',
                                        fontWeight: '400',
                                    }}
                                >
                                    Credentials already registered
                                </div>
                            )}
                        </div>
                    )}

                    {/* {!usingMobile && (
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <LoadingSpinnerContainer
                                loadingText="We received your utility credentials and are processing your data"
                                active={eleFetching}
                                usingMobile={usingMobile}
                            ></LoadingSpinnerContainer>
                        </div>
                    )} */}
                </>
            )}
            {!validSession && <div>Please Log In Before Adding User Data</div>}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
    eleUtilities: state.utilities.eleUtils,
}))(ElectricCredsPage);
