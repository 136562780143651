import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import * as mobileActions from './store/mobile';
import { useAppDispatch } from './store/index';
import HomePage from './pages/HomePage';
import OAuthRedirectPage from './pages/OAuthRedirectPage';
import ElectricCredsPage from './pages/ElectricCredsPage';
import GasCredsPage from './pages/GasCredsPage';
import ConfirmInfoPage from './pages/ConfirmInfoPage';
import CalculatingPage from './pages/CalculatingPage';
import TestingPage from './pages/TestingPage';
import SignupPage from './pages/SignupPage';
import CustomerFormPage from './pages/CustomerFormPage';
import OnboardingPageWrapper from './wrappers/OnboardingPageWrapper';
import DashboardPageWrapper from './wrappers/DashboardPageWrapper';
import DataVisualizationPage from './pages/DataVisualizationPage';
import { validateSession } from './store/session';

function _calculateScrollbarWidth() {
    document.documentElement.style.setProperty(
        '--scrollbar-width',
        window.innerWidth - document.documentElement.clientWidth + 'px',
    );
}
// recalculate on resize
window.addEventListener('resize', _calculateScrollbarWidth, false);
// recalculate on dom load
document.addEventListener('DOMContentLoaded', _calculateScrollbarWidth, false);
// recalculate on load (assets loaded as well)
window.addEventListener('load', _calculateScrollbarWidth);

function App() {
    const dispatch = useAppDispatch();
    const windowWidth = useRef(window.innerWidth);
    const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    useEffect(() => {
        dispatch(validateSession());
        if (windowWidth.current < 850) {
            dispatch(mobileActions.setUsingMobile(true));
        }
    }, []);

    return (
        <>
            <div className="App">
                <Routes>
                    <Route path="/" element={<OnboardingPageWrapper ComponentPage={HomePage} />}></Route>
                    <Route path="/signup" element={<OnboardingPageWrapper ComponentPage={SignupPage} />}></Route>
                    <Route
                        path="/planselect"
                        element={<OnboardingPageWrapper ComponentPage={CustomerFormPage} />}
                    ></Route>
                    <Route
                        path="/electric"
                        element={<OnboardingPageWrapper ComponentPage={ElectricCredsPage} />}
                    ></Route>
                    <Route path="/gas" element={<OnboardingPageWrapper ComponentPage={GasCredsPage} />}></Route>
                    <Route path="/confirm" element={<OnboardingPageWrapper ComponentPage={ConfirmInfoPage} />}></Route>
                    <Route
                        path="/calculating"
                        element={<OnboardingPageWrapper ComponentPage={CalculatingPage} />}
                    ></Route>
                    <Route
                        path="/oauthredirect/:utility_slug"
                        element={<OnboardingPageWrapper ComponentPage={OAuthRedirectPage} />}
                    ></Route>
                    {/* <Route
                        path="/dashboard"
                        element={<DashboardPageWrapper ComponentPage={DataVisualizationPage} />}
                    ></Route> */}
                </Routes>
                {/* <Routes>
                    {REACT_APP_ENVIRONMENT === 'dev' && <Route path="/test" element={<TestingPage />}></Route>}
                </Routes> */}
            </div>
        </>
    );
}

export default App;
